import React, { useEffect, ReactNode, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UiHelper from 'src/helpers/UiHelper';

import { RootState } from '../../store/store';
import { IError } from '../../store/app/app.types';
import { IObjectHash } from '../../types';
import { setIsMenuVisible } from '../../store/ui/ui.slice';
import SystemHelper from '../../helpers/SystemHelper';
import NotFound from '../common/contentWrappers/NotFound';
import MarketNotAvailable from '../common/contentWrappers/MarketNotAvailable';

const ErrorHandler = memo(function ErrorHandler({ children }: Readonly<{ children?: ReactNode }>): JSX.Element {
    const errors = useSelector((state: RootState) => state.app.errors);

    const dispatch = useDispatch();
    const error: IObjectHash<IError> = errors ?? ({} as IObjectHash<IError>);
    const showError = UiHelper.HaveError(errors) && !error.silent;
    const isNoValidMarket = UiHelper.HaveNoValidMarketError(errors) && !error.silent;

    const clearErrorCb = () => SystemHelper.DismissError(dispatch);

    useEffect(() => {
        dispatch(setIsMenuVisible({ isMenuVisible: !showError && !isNoValidMarket }));
    }, [dispatch, showError, isNoValidMarket]);

    if (isNoValidMarket) {
        return <MarketNotAvailable />;
    }

    if (showError) {
        return <NotFound clearErrorCb={clearErrorCb} />;
    }

    return <>{children}</>;
});

export default ErrorHandler;

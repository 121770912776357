import * as en_US from '../i18n/locales/translations/en-US.json';
import * as en_GB from '../i18n/locales/translations/en-GB.json';
import * as ar_AE from '../i18n/locales/translations/ar-AE.json';
import * as cs_CZ from '../i18n/locales/translations/cs-CZ.json';
import * as de_DE from '../i18n/locales/translations/de-DE.json';
import * as el_GR from '../i18n/locales/translations/el-GR.json';
import * as es_ES from '../i18n/locales/translations/es-ES.json';
import * as hr_HR from '../i18n/locales/translations/hr-HR.json';
import * as iw_IL from '../i18n/locales/translations/iw-IL.json';
import * as pl_PL from '../i18n/locales/translations/pl-PL.json';
import * as pt_PT from '../i18n/locales/translations/pt-PT.json';

export default class AnI18NextLibHelper {
    public static loadLang = (lang: string) => {
        let anI18Nextlib: any = en_US;

        switch (lang) {
            case 'ar-AE':
                anI18Nextlib = ar_AE;
                break;
            case 'cs-CZ':
                anI18Nextlib = cs_CZ;
                break;
            case 'de-DE':
                anI18Nextlib = de_DE;
                break;
            case 'en-US':
                anI18Nextlib = en_US;
                break;
            case 'en-GB':
                anI18Nextlib = en_GB;
                break;
            case 'el-GR':
                anI18Nextlib = el_GR;
                break;
            case 'es-ES':
                anI18Nextlib = es_ES;
                break;
            case 'hr-HR':
                anI18Nextlib = hr_HR;
                break;
            case 'iw-IL':
                anI18Nextlib = iw_IL;
                break;
            case 'pl-PL':
                anI18Nextlib = pl_PL;
                break;
            case 'pt-PT':
                anI18Nextlib = pt_PT;
                break;
            default:
                anI18Nextlib = en_US;
                break;
        }

        return anI18Nextlib['default'];
    };

    private static translate = (dict: any, keys: string[]): string => {
        let output = '';

        if (dict && typeof dict === 'object' && keys?.length > 0) {
            const val = dict[keys[0]];

            if (val && typeof val === 'object' && keys?.length > 1) {
                output = AnI18NextLibHelper.translate(val, keys.slice(1));
            } else {
                output = val;
            }
        }

        return output;
    };

    public static Translate = (dictOrig: any, key: string, subs?: any): string => {
        let output = AnI18NextLibHelper.translate(dictOrig ?? AnI18NextLibHelper.loadLang(''), key?.split('.'));

        if (output && subs) {
            for (const fld in subs) {
                // Note:  replaceAll() break PR pipeline
                // output = output.replaceAll(token, `${subs[fld]}`);

                const token = `{{${fld}}}`;

                while (output.indexOf(token) > -1) {
                    output = output.replace(token, `${subs[fld]}`);
                }
            }
        }

        return output;
    };
}

import { useSelector } from 'react-redux';
import React from 'react';
import clsx from 'clsx';

import { ITranslator } from '../../../types';
import { InsulinUsageAverageMorningNumberMorning } from '../../../model/models';
import { RootState } from '../../../store/store';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import iconTodNight from '../../../assets/images/avg-daily-tod-night.png';
import iconTodMorning from '../../../assets/images/avg-daily-tod-morning.png';
import iconTodAfternoon from '../../../assets/images/avg-daily-tod-afternoon.png';
import iconTodEvening from '../../../assets/images/avg-daily-tod-evening.png';
import StringHelper from '../../../helpers/StringHelper';

const getClassToUse = (value: number) => (value ? styleGuide.loyaltyValue : styleGuide.loyaltyValueNull);

function TimeRange({
    iconSrc,
    label,
    todData,
    market,
}: Readonly<{
    iconSrc: string;
    label: string;
    todData: InsulinUsageAverageMorningNumberMorning;
    market: string;
}>) {
    return (
        <span className={clsx(styleGeneral.cell, styleGuide.loyaltyCell)}>
            <img src={iconSrc} className={clsx(styleGuide.icon)} alt={label} />
            <span className={styleGuide.loyaltyLabelTop}>{label}</span>
            <span className={styleGuide.loyaltyLabelBottom}>
                {todData?.timeRange?.start ?? ' '} - {todData?.timeRange?.end ?? ' '}
            </span>
            <span className={getClassToUse(todData?.avgNumber)}>
                {StringHelper.FormatNumber(todData?.avgNumber, market, 1)}
            </span>
        </span>
    );
}

function LoyaltyInsulinUsageAvgDaily({ translate }: { translate: ITranslator }) {
    const currentInsulinUsage = useSelector((state: RootState) => state.dashboard.currentInsulinUsage);
    const market = useSelector((state: RootState) => state.profile.data.culture.market);

    return !currentInsulinUsage?.dailyUsage?.bolusStatistics ? null : (
        <div className={styleGuide.headerLoyalty} data-testid="loyalty_insulin_usage_avg_daily">
            <div className={styleGuide.loyaltyInsulinUsage}>
                <div className={clsx(styleGeneral.table, styleGuide.loyaltyRow2)}>
                    <div className={styleGeneral.row}>
                        <span className={clsx(styleGeneral.cell, styleGuide.loyaltyLeft)}>
                            <span className={styleGuide.avgDailyBolus}>
                                {StringHelper.FormatNumber(
                                    currentInsulinUsage.dailyUsage.bolusStatistics?.avgNumber,
                                    market,
                                    1
                                )}
                            </span>
                            <span className={styleGuide.avgDailyBolusLabel}>
                                <span className={styleGuide.inner}>
                                    {translate('loyalty.insulin.avgDailyBolus.bolusesPerDayPart1')}
                                </span>
                                <span className={styleGuide.inner}>
                                    {translate('loyalty.insulin.avgDailyBolus.bolusesPerDayPart2')}
                                </span>
                            </span>
                        </span>
                    </div>
                    <div className={styleGeneral.row}>
                        <span className={clsx(styleGeneral.cell, styleGuide.loyaltyBottomAlt)}>
                            <div className={styleGeneral.table}>
                                <div className={clsx(styleGeneral.row, styleGuide.loyaltyRow)}>
                                    <TimeRange
                                        iconSrc={iconTodNight}
                                        label={translate('loyalty.insulin.avgDailyBolus.nighttime')}
                                        todData={currentInsulinUsage.dailyUsage.bolusStatistics?.timeOfDay?.nighttime}
                                        market={market}
                                    />
                                    <TimeRange
                                        iconSrc={iconTodMorning}
                                        label={translate('loyalty.insulin.avgDailyBolus.morning')}
                                        todData={currentInsulinUsage.dailyUsage.bolusStatistics?.timeOfDay?.morning}
                                        market={market}
                                    />
                                    <TimeRange
                                        iconSrc={iconTodAfternoon}
                                        label={translate('loyalty.insulin.avgDailyBolus.afternoon')}
                                        todData={currentInsulinUsage.dailyUsage.bolusStatistics?.timeOfDay?.afternoon}
                                        market={market}
                                    />
                                    <TimeRange
                                        iconSrc={iconTodEvening}
                                        label={translate('loyalty.insulin.avgDailyBolus.evening')}
                                        todData={currentInsulinUsage.dailyUsage.bolusStatistics?.timeOfDay?.evening}
                                        market={market}
                                    />
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoyaltyInsulinUsageAvgDaily;

import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import InsightCard from './insightCard';

import { ICallback } from '../../types';
import { IInsight } from '../../store/insights/insights.types';
import { RootState } from '../../store/store';
import InsightHelper from '../../helpers/InsightHelper';
import UiHelper from '../../helpers/UiHelper';
import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';

function InsightsByPattern({
    scrollActive1,
    scrollActive2,
    insights,
    testId,
    cbClickFn,
}: {
    scrollActive1: boolean;
    scrollActive2: boolean;
    insights: IInsight[];
    testId: string;
    cbClickFn: ICallback<void>;
}) {
    const anI18Nextlib = useSelector((state: RootState) => state.app.anI18Nextlib);
    const dashboardHasReferrer = useSelector((state: RootState) => state.dashboard.dashboardHasReferrer);
    const cachedInsights = useSelector((state: RootState) => state.insights.cachedInsights);
    const market = useSelector((state: RootState) => state.profile.data.culture.market);

    const translate = (key: string, subs?: any) => UiHelper.Translate(anI18Nextlib, key, subs);

    const dashBoardZoomCSS =
        scrollActive1 && dashboardHasReferrer
            ? styleGeneral.dashboardZoomInScroll1OnScroll2OffAlt
            : styleGeneral.dashboardZoomInScroll1OnScroll2Off;

    return insights?.length > 0 ? (
        <div className={clsx(styleGuide.insights, styleGuide.insightsByPattern)} data-testid="insights-by-pattern">
            <div
                className={clsx(
                    styleGuide.solidBg,
                    styleGuide.header,
                    styleGeneral.dashboardZoomInScroll1OffScroll2Off,
                    dashBoardZoomCSS
                )}
            >
                <div className={clsx(styleGuide.line)}></div>
                <div
                    className={clsx(
                        styleGuide.title,
                        styleGeneral.toCapitalize,
                        scrollActive2 && styleGeneral.inScroll2
                    )}
                >
                    {translate('patternsFound')}
                </div>
            </div>

            {insights.map((insight, idx) => {
                const insightAttributes = InsightHelper.GetInsightAttributes(
                    anI18Nextlib,
                    cachedInsights,
                    insight,
                    translate,
                    market
                );
                const flaggedDay = InsightHelper.GetFlaggedDays(anI18Nextlib, insight);
                const key = `insight_${insight.insightId}_${idx}`;

                return (
                    <div key={`${key}_div`} data-testid={`${testId}_${idx}`}>
                        <InsightCard
                            test-id={'insight-card-id'}
                            key={key}
                            anchor={`${key}_anchor`}
                            cbClickFn={(payload: any) => cbClickFn(payload)}
                            insight={insight}
                            insightAttributes={insightAttributes}
                            flaggedDay={flaggedDay}
                        />
                    </div>
                );
            })}
        </div>
    ) : null;
}

export default InsightsByPattern;

import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch, useSelector } from 'react-redux';
import { OktaAuth } from '@okta/okta-auth-js';
import clsx from 'clsx';

import MenuLabel from './MenuLabel';

import { IMenuEntry } from '../../types';
import { sendAzureEvent } from '../../helpers/appInsights';
import { RootState } from '../../store/store';
import { setAnchorAllReports, setIsMenuOpen } from '../../store/ui/ui.slice';
import { setDashboardMetaData } from '../../store/dashboard/dashboard.slice';
import styleGeneral from '../../styles/general.module.scss';
import omnipodDiscover from '../../assets/images/discover-logo.svg';
import MenuHelper from '../../helpers/MenuHelper';
import UiHelper from '../../helpers/UiHelper';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import UtilityHelper from '../../helpers/UtilityHelper';
import SystemHelper from '../../helpers/SystemHelper';

const signOut = async (keyToAllowSignOut: string, oktaAuth: OktaAuth) => {
    if (UtilityHelper.SettingGet(keyToAllowSignOut) !== '1') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setTimeout(async () => signOut(keyToAllowSignOut, oktaAuth), 1000);
    } else {
        sendAzureEvent(ConstantsHelper.analyticsEventNames.LOG_OUT);
        await oktaAuth.signOut();
    }
};

const { loginURL } = MenuHelper.Init();

interface IMenuProps {
    menuEntries: IMenuEntry[];
}

function Menu({ menuEntries }: Readonly<IMenuProps>) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { oktaAuth } = useOktaAuth();

    const menuContainerRef = useRef(null);

    const isMenuOpen = useSelector((state: RootState) => state.ui.isMenuOpen);
    const isMenuVisible = useSelector((state: RootState) => state.ui.isMenuVisible);
    const dashboardBeg = useSelector((state: RootState) => state.dashboard.dashboardBeg);
    const dashboardEnd = useSelector((state: RootState) => state.dashboard.dashboardEnd);
    const contributingEventBeg = useSelector((state: RootState) => state.insights.contributingEventBeg);
    const contributingEventEnd = useSelector((state: RootState) => state.insights.contributingEventEnd);
    const contributingEventId = useSelector((state: RootState) => state.insights.contributingEventId);
    const insightId = useSelector((state: RootState) => state.insights.insightId);

    const browserInfo = SystemHelper.GetBrowserInfo();
    const notIsProd = !SystemHelper.IsProd();
    const isUnsupportedBrowser = !browserInfo.supported;

    const handleLogoClick = () => {
        dispatch(setAnchorAllReports({ anchorAllReports: undefined }));

        dispatch(
            setDashboardMetaData({
                dashboardBeg: undefined,
                dashboardEnd: undefined,
                dashboardHasReferrer: undefined,
            })
        );

        dispatch(setIsMenuOpen({ isMenuOpen: false }));
    };

    const toggleMenu = () => {
        dispatch(setIsMenuOpen({ isMenuOpen: !isMenuOpen }));
    };

    const handleNavItemClick = () => {
        toggleMenu();
    };

    const handleSignInClick = () => {
        MenuHelper.redirectToLogin(loginURL);
    };

    const handleSignOutClick = useCallback(async () => {
        const keyToAllowSignOut = ConstantsHelper.IntersessionKeys.signOutCanProceed;

        UiHelper.SignOutArm({
            dispatch,
            contributingEventBeg,
            contributingEventEnd,
            contributingEventId,
            insightId,
            dashboardBeg,
            dashboardEnd,
        });

        /* CAUTION:
            Do not dispatch any Redux actions before signout has been initiated as these actions
            may trigger a component refresh while signout has not been completed.  This could
            sometimes cause the session access token to refresh itself and in effect cancel the
            signout.
        */
        await signOut(keyToAllowSignOut, oktaAuth);
    }, [
        dispatch,
        contributingEventBeg,
        contributingEventEnd,
        contributingEventId,
        insightId,
        dashboardBeg,
        dashboardEnd,
        oktaAuth,
    ]);

    const handleMenuEntryClick = (menuEntryKey: MenuHelper) => {
        switch (menuEntryKey) {
            case MenuHelper.MenuEntrySignIn.key:
                return handleSignInClick();
            case MenuHelper.MenuEntrySignOut.key:
                return handleSignOutClick();
            default:
                return handleNavItemClick();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuContainerRef.current && !menuContainerRef.current.contains(event.target) && isMenuOpen) {
                dispatch(setIsMenuOpen({ isMenuOpen: !isMenuOpen }));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch, isMenuOpen]);

    return (
        <div className={styleGeneral.main} ref={menuContainerRef}>
            {notIsProd && <div className={styleGeneral.nonProdUse}>{t('nonProdUse')}</div>}

            {isUnsupportedBrowser && <div className={styleGeneral.unsupportedBrowser}>{t('unsupportedBrowser')}</div>}

            <Navbar
                className={clsx(
                    styleGeneral.navbarBox,
                    isMenuOpen ? styleGeneral.layerTopmost : styleGeneral.layerTopmostLess1,
                    'navbar',
                    'navbar-light',
                    'bg-light'
                )}
                collapseOnSelect
                expand={false}
                expanded={isMenuOpen}
                bg="light"
                variant="light"
            >
                <Tooltip title={t('goToHomeScreen')}>
                    <Navbar.Brand
                        as={Link}
                        to="/"
                        className={clsx(styleGeneral.navbarBrand, styleGeneral.banner)}
                        onClick={handleLogoClick}
                    >
                        <img className={styleGeneral.navbarLogo} src={omnipodDiscover} alt="Omnipod Discover™" />
                    </Navbar.Brand>
                </Tooltip>

                {isMenuVisible && (
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        data-testid="menu-button"
                        className={clsx(styleGeneral.navbarHamburger)}
                        onClick={toggleMenu}
                    >
                        <div className={styleGeneral.menu}>
                            <div className={clsx(styleGeneral.bar1, isMenuOpen && styleGeneral.bar1Change)}></div>
                            <div className={clsx(styleGeneral.bar2, isMenuOpen && styleGeneral.bar2Change)}></div>
                            <div className={clsx(styleGeneral.bar3, isMenuOpen && styleGeneral.bar3Change)}></div>
                        </div>
                    </Navbar.Toggle>
                )}

                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    className={clsx(styleGeneral.scrollable, styleGeneral.menuFrame)}
                >
                    <Nav className="mr-auto" data-testid="main-menu">
                        {menuEntries
                            ?.filter((menuEntry) => menuEntry.label?.length > 0)
                            ?.map((menuEntry, idx: number) => {
                                return (
                                    <span key={`navSpan${idx}`}>
                                        {menuEntry?.preSeparate && idx > 0 && (
                                            <span key={`navSpanT${idx}`} className={styleGeneral.menuItemPreSeparate} />
                                        )}

                                        <Nav.Link
                                            data-testid={menuEntry?.label?.replace(' ', '_')}
                                            key={`navRow${idx}`}
                                            eventKey={`navLink${idx}`}
                                            as={Link}
                                            to={menuEntry.path ?? ''}
                                            onClick={() => handleMenuEntryClick(menuEntry.key)}
                                        >
                                            <MenuLabel menuEntry={menuEntry} />
                                        </Nav.Link>

                                        {menuEntry?.postSeparate && (
                                            <span key={`navSpanB${idx}`} className={styleGeneral.menuItemPreSeparate} />
                                        )}
                                    </span>
                                );
                            })}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default Menu;

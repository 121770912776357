import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isAppMarketTranslationTextRightToLeft } from '../helpers/isAppMarketTranslationTextRightToLeft';
import { RootState } from '../store/store';

export const RIGHT_TO_LEFT = 'rtl';

export const LEFT_TO_RIGHT = 'ltr';

export const useDocumentOrientation = () => {
    const [documentOrientation, setDocumentOrientation] = useState<'ltr' | 'rtl'>(LEFT_TO_RIGHT);
    const profileData = useSelector((state: RootState) => state.profile.data);
    const { language } = profileData.culture;

    useEffect(() => {
        if (language && isAppMarketTranslationTextRightToLeft(language)) {
            setDocumentOrientation(RIGHT_TO_LEFT);
        }
    }, [language]);

    return documentOrientation;
};

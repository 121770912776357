import { IAppState } from '../../app.types';
import ConstantsHelper from '../../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../../helpers/UtilityHelper';
import UiHelper from '../../../../helpers/UiHelper';
import AnI18NextLibHelper from '../../../../helpers/AnI18NextLibHelper';
import StringHelper from '../../../../helpers/StringHelper';

const fetchHcpReportFulfilled = (state: IAppState, action: any) => {
    const callKey = UtilityHelper.MakeHttpCallKey(ConstantsHelper.httpCallAndErrorKeys.fetchHcpReport, [
        `${action.meta?.arg?.numDays}`,
    ]);
    if (callKey) {
        delete state.activeHttpCalls[callKey];
    }
    // download to new tab handler
    state.errors[callKey] = UiHelper.CreateErrorMessage({
        level: ConstantsHelper.ErrorLevels.success,
        message: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.hcpReportBody', {
            dayCount: StringHelper.Pluralize(action.payload, 'day', undefined, ConstantsHelper.DefaultMarket),
        }),
        title: AnI18NextLibHelper.Translate(state.anI18Nextlib, 'errors.hcpReportTitle'),
        showAlways: true,
        sticky: true,
    });
    state.loadingSemaphore--;
};

export default fetchHcpReportFulfilled;

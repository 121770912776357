import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import { ITranslator } from '../../types';
import styleGuide from '../../styles/styleGuide.module.scss';
import UtilityHelper from '../../helpers/UtilityHelper';
import StringHelper from '../../helpers/StringHelper';
import { RootState } from '../../store/store';

function TrendLabel({
    showUnits,
    translate,
    value,
}: {
    showUnits: boolean;
    translate: ITranslator;
    value: number | null;
}) {
    const market = useSelector((state: RootState) => state.profile.data.culture.market);

    const isNull = UtilityHelper.IsNull(value);
    const isNumeric = UtilityHelper.IsNumeric(value);

    return (
        <span
            className={clsx(
                styleGuide.loyaltyLabelOther,
                (isNumeric && value) || isNull ? styleGuide.loyaltyValueSmall : styleGuide.loyaltyValueNullSmall
            )}
        >
            {isNumeric ? StringHelper.FormatNumber(value, market, 1) : translate('notAvailable')}
            {isNumeric && showUnits && <span className={styleGuide.loyaltyValueUnit}>{translate('unit')}</span>}
        </span>
    );
}

export default TrendLabel;

import { isEmpty } from 'lodash';
import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import React from 'react';

import TimeOfDayPatternHeader from './timeOfDayPatternHeader';

import { ICallback, IInsightAttr, ITranslator } from '../../types';
import { IInsight } from '../../store/insights/insights.types';
import { RootState } from '../../store/store';
import InsightHelper from '../../helpers/InsightHelper';
import StringHelper from '../../helpers/StringHelper';
import EventCell from '../common/eventCell';
import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';
import NavigationHelper from '../../helpers/NavigationHelper';

function InsightDetail({
    parentScrollNode,
    cbClickEventFn,
    translate,
    insight,
    insightAttributes,
    scrollActive1,
}: Readonly<{
    parentScrollNode: React.ReactNode;
    translate: ITranslator;
    scrollActive1: boolean;
    cbClickEventFn: ICallback<void>;
    insight: IInsight;
    insightAttributes: IInsightAttr;
}>) {
    const dashboardHasReferrer = useSelector((state: RootState) => state.dashboard.dashboardHasReferrer);
    const dashboardBeg = useSelector((state: RootState) => state.dashboard.dashboardBeg);
    const dashboardEnd = useSelector((state: RootState) => state.dashboard.dashboardEnd);
    const insightId = useSelector((state: RootState) => state.insights.insightId);
    const cachedInsights = useSelector((state: RootState) => state.insights.cachedInsights);
    const market = useSelector((state: RootState) => state.profile.data.culture.market);

    if (!insight) {
        return null;
    }

    const eventCount = insight?.windowEgvEvent?.length;
    const typeNamePlural: string = StringHelper.Pluralize(eventCount, `${insightAttributes.name} event`, false, market);
    const eventCountDisplay: string = StringHelper.Pluralize(eventCount, undefined, undefined, market);
    const timeOfDayPatterns: IInsight[] = InsightHelper.GetTimeOfDayPatterns(cachedInsights, insight);

    return (
        <div className={styleGuide.insightEntry}>
            <div className={styleGuide.whiteBackground}>
                <div
                    className={clsx(
                        styleGuide.summary,
                        styleGeneral.eventsZoomInScroll1OffScroll2Off,
                        scrollActive1 && styleGeneral.eventsZoomInScroll1OnScroll2Off
                    )}
                >
                    <div
                        className={clsx(
                            styleGuide.header,
                            styleGeneral.toCapitalize,
                            scrollActive1 && styleGeneral.inScroll
                        )}
                    >
                        {insightAttributes.scrn1Title}
                    </div>

                    <div className={clsx(styleGuide.timeRange, scrollActive1 && styleGeneral.inScroll)}>
                        {insightAttributes.scrn2Title}
                    </div>
                </div>

                <div className={styleGuide.detail}>
                    {isEmpty(timeOfDayPatterns) ? (
                        <>
                            <div
                                className={clsx(styleGuide.banner, styleGeneral.eventsZoomInScroll1OffScroll2Off, {
                                    [styleGeneral.eventsZoomInScroll1OnScroll2OnBorderOn]: scrollActive1,
                                })}
                            >
                                <span
                                    className={clsx(
                                        styleGuide.count,
                                        insightAttributes.style,
                                        scrollActive1 && styleGeneral.inScroll
                                    )}
                                >
                                    {eventCountDisplay}
                                </span>

                                <span
                                    className={clsx(
                                        styleGuide.label,
                                        styleGeneral.toCapitalize,
                                        scrollActive1 && styleGeneral.inScroll
                                    )}
                                >
                                    {typeNamePlural}
                                </span>
                            </div>

                            <div className={styleGuide.eventList}>
                                {insight.windowEgvEvent?.map((contributingEvent, index) => (
                                    <EventCell
                                        key={`insightRow${index}`}
                                        translate={translate}
                                        anchor={`contributingEvent_${index}`}
                                        cbClickFn={(payload: any) => cbClickEventFn(payload)}
                                        url={NavigationHelper.GenUrlEvent(
                                            dashboardBeg,
                                            dashboardEnd,
                                            dashboardHasReferrer,
                                            InsightHelper.ConvertInsightIdsChildAndParentToSingle(insightId, insightId),
                                            contributingEvent.eventId,
                                            contributingEvent.beg,
                                            contributingEvent.end
                                        )}
                                        contributingEvent={contributingEvent}
                                        insightAttributes={insightAttributes}
                                        insightType={insight.insightType}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        timeOfDayPatterns.map((pattern, patternIndex) => (
                            <React.Fragment key={`time_of_day_pattern-${patternIndex}`}>
                                {patternIndex >= 1 ? <Divider className={styleGuide.divider} /> : null}

                                <TimeOfDayPatternHeader
                                    insightPattern={pattern}
                                    parentScrollNode={parentScrollNode}
                                    translate={translate}
                                />

                                <div className={clsx(styleGuide.eventList, styleGuide.timeOfDayEventList)}>
                                    {pattern.windowEgvEvent?.map((contributingEvent, eventIndex) => (
                                        <EventCell
                                            key={`insightRow${eventIndex}`}
                                            translate={translate}
                                            anchor={`contributingEvent_${eventIndex}`}
                                            cbClickFn={(payload: any) => cbClickEventFn(payload)}
                                            url={NavigationHelper.GenUrlEvent(
                                                dashboardBeg,
                                                dashboardEnd,
                                                dashboardHasReferrer,
                                                InsightHelper.ConvertInsightIdsChildAndParentToSingle(
                                                    insightId,
                                                    insightId
                                                ),
                                                contributingEvent.eventId,
                                                contributingEvent.beg,
                                                contributingEvent.end
                                            )}
                                            contributingEvent={contributingEvent}
                                            insightAttributes={insightAttributes}
                                            insightType={insight.insightType}
                                        />
                                    ))}
                                </div>
                            </React.Fragment>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

export default InsightDetail;

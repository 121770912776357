import { Patient } from 'src/model/patient';

export type UserType = 'patient';

export interface PatientSmsNumber {
    smsNumber: string;
}

export interface IPatient extends Patient {
    reportDaysAvailableString: string;
    isEnrolled: boolean;
    isUnenrolled: boolean;
    isOnboarded: boolean;
    receivedData: boolean;
    userType: UserType;
    culture: Culture;
}

export interface IProfileState {
    data: IPatient;
}

export interface Culture {
    market: string;
    language: string;
    units: Units;
}

export enum Units {
    MG_DL = 'mg/dL',
    MMOL_L = 'mmol/L',
}

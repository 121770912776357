import { useTranslation } from 'react-i18next';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import styleGeneral from '../../styles/general.module.scss';

function BtnBack({ url }: { url: string }) {
    const { t } = useTranslation();

    return (
        <div
            className={clsx(
                styleGeneral.buttonEmpty,
                styleGeneral.toLeft,
                styleGeneral.padLeft,
                styleGeneral.padTop,
                styleGeneral.padBottom
            )}
        >
            <Link className={clsx(styleGeneral.link, styleGeneral.backButton)} to={url ?? '#'}>
                <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: 4 }} />
                {t('backButton')}
            </Link>
        </div>
    );
}

export default BtnBack;

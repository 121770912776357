import ConstantsHelper from 'src/helpers/ConstantsHelper';

import { IProfileState } from './profile.types';

export const profileInitialState: IProfileState = {
    data: {
        confidentialityAgreementAccepted: false,
        dateOfBirth: null,
        disclaimerSeen: false,
        eulaAccepted: false,
        firstJoined: null,
        firstName: null,
        id: null,
        isEnrolled: false,
        isOnboarded: false,
        isUnenrolled: false,
        lastName: null,
        physicianName: null,
        receivedData: false,
        reportDaysAvailable: null,
        reportDaysAvailableString: null,
        userType: null,
        welcomeSeen: false,
        culture: {
            market: ConstantsHelper.DefaultMarket,
            language: ConstantsHelper.DefaultLanguage,
            units: ConstantsHelper.DefaultUnits,
        },
    },
};

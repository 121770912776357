import arAE from './translations/ar-AE.json';
import csCZ from './translations/cs-CZ.json';
import deDE from './translations/de-DE.json';
import elGR from './translations/el-GR.json';
import enGB from './translations/en-GB.json';
import enUS from './translations/en-US.json';
import esES from './translations/es-ES.json';
import hrHR from './translations/hr-HR.json';
import iwIL from './translations/iw-IL.json';
import plPL from './translations/pl-PL.json';
import ptPT from './translations/pt-PT.json';

export const resources = {
    'ar-AE': {
        translation: arAE,
    },
    'cs-CZ': {
        translation: csCZ,
    },
    'de-DE': {
        translation: deDE,
    },
    'el-GR': {
        translation: elGR,
    },
    'en-GB': {
        translation: enGB,
    },
    'en-US': {
        translation: enUS,
    },
    'es-ES': {
        translation: esES,
    },
    'iw-IL': {
        translation: iwIL,
    },
    'hr-HR': {
        translation: hrHR,
    },
    'pl-PL': {
        translation: plPL,
    },
    'pt-PT': {
        translation: ptPT,
    },
};

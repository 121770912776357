import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';

import { LearningMaterialProgressResponseItem, LearningMaterialResourceResponseItem } from '../../model/models';
import { IDashboardRequest } from '../../types';
import { RootState } from '../store';
import { ICachedResponse, ThunkError } from '../root.types';
import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';
import SystemHelper from '../../helpers/SystemHelper';

export const fetchLearningMaterialResources = createAsyncThunk<
    ICachedResponse<LearningMaterialResourceResponseItem>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient/fetchLearningMaterialResources', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;
    const dashboardState = state.dashboard;

    const learningMaterialResourceItem: LearningMaterialResourceResponseItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`, `i18n=${params.i18n ?? 'en_US'}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/learning-material/resources?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NO_CONTENT ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        learningMaterialResourceItem.item = response.data?.data?.item;
    } else {
        learningMaterialResourceItem.item = {
            resources: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(
                appState?.anI18Nextlib,
                'errors.failedLearningMaterialResource'
            )} (${response.msg})`,
        });
    }

    return {
        dashboardState,
        data: learningMaterialResourceItem,
    };
});

export const fetchLearningMaterialOp5Progress = createAsyncThunk<
    ICachedResponse<LearningMaterialProgressResponseItem>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient/fetchLearningMaterialOp5Progress', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;
    const dashboardState = state.dashboard;

    const learningMaterialProgress: LearningMaterialProgressResponseItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`, `i18n=${params.i18n ?? 'en_US'}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/learning-material/op5-progress?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NO_CONTENT ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        learningMaterialProgress.item = response.data?.data?.item;
    } else {
        learningMaterialProgress.item = {
            progress: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(
                appState?.anI18Nextlib,
                'errors.failedLearningMaterialProgress'
            )} (${response.msg})`,
        });
    }

    return {
        dashboardState,
        data: learningMaterialProgress,
    };
});

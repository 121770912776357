import { useTranslation } from 'react-i18next';
import { ResponsivePie } from '@nivo/pie';
import { useSelector } from 'react-redux';
import React from 'react';
import clsx from 'clsx';

import { IInsulinUsage } from '../../../store/dashboard/dashboard.types';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import UtilityHelper from '../../../helpers/UtilityHelper';
import { RootState } from '../../../store/store';
import StringHelper from '../../../helpers/StringHelper';

const comparisonDict = {
    eq: '=',
    ne: '≠',
    gte: '≥',
    gt: '>',
    lte: '≤',
    lt: '<',
};

const getComparisonMarkup = (summary: IInsulinUsage): JSX.Element => {
    const availableKeys = Object.keys(summary?.dailyUsage?.goals?.bolusRatio);
    const matchedKey = availableKeys.find((k) => comparisonDict[k]);

    return (
        matchedKey && (
            <>
                :&nbsp;{comparisonDict[matchedKey]}&nbsp;{summary?.dailyUsage?.goals?.bolusRatio[matchedKey]}%
            </>
        )
    );
};

const getChartSettings = (data: any): any => {
    return {
        margin: { top: 0, right: 0, bottom: 0, left: 0 },
        indexBy: 'index',
        innerRadius: 0.8,
        cornerRadius: 20,
        padAngle: 2,
        enableLabel: false,
        arcLabelsTextColor: 'transparent',
        labelTextColor: 'inherit:darker(1.4)',
        labelSkipWidth: 16,
        labelSkipHeight: 16,
        animate: true,
        axisBottom: null,
        axisLeft: null,
        axisRight: null,
        axisTop: null,
        colors: [ConstantsHelper.Chart.colorBasal, ConstantsHelper.Chart.colorBolus],
        data,
        enableArcLinkLabels: false,
        enablePointLabel: false,
        xScale: null,
        yScale: null,
    };
};

const getChartStyle = (bolus: number) => (bolus > 99 ? styleGuide.loyaltyChartAlt : styleGuide.loyaltyChart);

function LoyaltyInsulinModeUsageChart({ showUnits }: { showUnits: boolean }) {
    const { t } = useTranslation();

    const currentInsulinUsage = useSelector((state: RootState) => state.dashboard.currentInsulinUsage);
    const market = useSelector((state: RootState) => state.profile.data.culture.market);

    const showUnitsBolus = showUnits && !UtilityHelper.IsNull(currentInsulinUsage.dailyUsage.insulinUnits?.bolus);
    const showUnitsBasal = showUnits && !UtilityHelper.IsNull(currentInsulinUsage.dailyUsage.insulinUnits?.basal);

    return (
        <div className={styleGuide.headerLoyalty}>
            <div className={clsx(styleGeneral.tableGeneric, styleGuide.loyaltyInsulinUsage)}>
                <div className={clsx(styleGeneral.table, styleGuide.loyaltyRow1)}>
                    <div className={styleGeneral.row}>
                        <span className={clsx(styleGeneral.cell, styleGuide.loyaltyLeft)}>
                            <span className={styleGeneral.table}>
                                <span className={styleGeneral.row}>
                                    <span className={styleGeneral.cell}>
                                        <span className={styleGuide.bolusPct}>
                                            {StringHelper.FormatNumber(
                                                currentInsulinUsage.dailyUsage.insulinRatio?.bolus,
                                                market,
                                                1
                                            )}
                                            %
                                        </span>
                                    </span>
                                </span>
                                <span className={styleGeneral.row}>
                                    <span className={styleGeneral.cell}>
                                        <span
                                            className={
                                                showUnitsBolus ? styleGuide.bolusLabelAlt : styleGuide.bolusLabel
                                            }
                                        >
                                            <span>{t('loyalty.insulin.bolus')}</span>
                                            <span className={styleGuide.goal}>
                                                {t('loyalty.insulin.goal')}
                                                {getComparisonMarkup(currentInsulinUsage)}
                                            </span>
                                        </span>
                                    </span>
                                </span>
                                {showUnitsBolus && (
                                    <span className={styleGeneral.row}>
                                        <span className={styleGeneral.cell}>
                                            <span className={styleGuide.bolusUnits}>
                                                <span>
                                                    {StringHelper.FormatNumber(
                                                        currentInsulinUsage.dailyUsage.insulinUnits?.bolus,
                                                        market,
                                                        1
                                                    )}{' '}
                                                    {t('unit')}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                )}
                                <span className={styleGeneral.row}>
                                    <span className={styleGeneral.cell}>
                                        <span className={showUnitsBasal ? styleGuide.basalPctAlt : styleGuide.basalPct}>
                                            <span>
                                                {StringHelper.FormatNumber(
                                                    currentInsulinUsage.dailyUsage.insulinRatio?.basal,
                                                    market,
                                                    0
                                                )}
                                                %
                                            </span>
                                        </span>
                                    </span>
                                </span>
                                <span className={styleGeneral.row}>
                                    <span className={styleGeneral.cell}>
                                        <span
                                            className={
                                                showUnitsBasal ? styleGuide.basalLabelAlt : styleGuide.basalLabel
                                            }
                                        >
                                            <span>{t('loyalty.insulin.basal')}</span>
                                        </span>
                                    </span>
                                </span>
                                {showUnitsBasal && (
                                    <span className={styleGeneral.row}>
                                        <span className={styleGeneral.cell}>
                                            <span className={styleGuide.basalUnits}>
                                                <span>
                                                    {StringHelper.FormatNumber(
                                                        currentInsulinUsage.dailyUsage.insulinUnits?.basal,
                                                        market,
                                                        1
                                                    )}{' '}
                                                    {t('unit')}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                )}
                            </span>
                        </span>
                        <span className={clsx(styleGeneral.cell, styleGuide.loyaltyRight)}>
                            <span className={getChartStyle(currentInsulinUsage.dailyUsage.insulinRatio?.bolus)}>
                                <ResponsivePie
                                    {...getChartSettings([
                                        {
                                            id: ConstantsHelper.DataSeriesLimited,
                                            value: currentInsulinUsage.dailyUsage.insulinRatio?.basal,
                                        },
                                        {
                                            id: ConstantsHelper.DataSeriesManual,
                                            value: currentInsulinUsage.dailyUsage.insulinRatio?.bolus,
                                        },
                                    ])}
                                />
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoyaltyInsulinModeUsageChart;

import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';

import styleGuide from '../../../styles/styleGuide.module.scss';

function MarketNotAvailable() {
    const { t } = useTranslation();

    return (
        <div className={clsx(styleGuide.marketNotAvailable)}>
            <div className={styleGuide.title}>{t('notAvailableIntl.header')}</div>
            <div className={styleGuide.description}>{t('notAvailableIntl.notAvailable')}</div>
        </div>
    );
}

export default MarketNotAvailable;

import { useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';
import { ResponsiveBar } from '@nivo/bar';
import { useSelector } from 'react-redux';

import { useDocumentOrientation, RIGHT_TO_LEFT } from 'src/hooks/useDocumentOrientation';

import StringHelper from '../../../helpers/StringHelper';
import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import ConstantsHelper from '../../../helpers/ConstantsHelper';
import { IInsight3 } from '../../../types';
import { RootState } from '../../../store/store';

function InsightSummaryTimesInRange({ summary }: Readonly<{ summary: IInsight3 }>) {
    const { t } = useTranslation();
    const { language: languageCode, market } = useSelector((state: RootState) => state.profile.data.culture);
    const documentOrientation = useDocumentOrientation();
    const isRightToLeft = documentOrientation === RIGHT_TO_LEFT;
    const timeInRangeStyleValues = isRightToLeft
        ? {
              styleGuideLeft: styleGuide.leftRightToLeft,
              syleGuideRight: styleGuide.rightRightToLeft,
              styleGuideValueLeft: styleGuide.high,
              syleGuideValueRight: styleGuide.low,
              captionLeft: t('high'),
              captionRight: t('low'),
              rightPctValue: StringHelper.PercentFormatter(languageCode, summary.timeInRangeLowPct),
              leftPctValue: StringHelper.PercentFormatter(languageCode, summary.timeInRangeHighPct),
          }
        : {
              styleGuideLeft: styleGuide.left,
              syleGuideRight: styleGuide.right,
              styleGuideValueLeft: styleGuide.low,
              syleGuideValueRight: styleGuide.high,
              captionLeft: t('low'),
              captionRight: t('high'),
              leftPctValue: StringHelper.PercentFormatter(languageCode, summary.timeInRangeLowPct),
              rightPctValue: StringHelper.PercentFormatter(languageCode, summary.timeInRangeHighPct),
          };

    const timeInRangeSettings = {
        ...timeInRangeStyleValues,
        ...{
            timeInRangePct: StringHelper.PercentFormatter(languageCode, summary.timeInRangePct),
        },
    };

    const getChartSettings = (low: number, normal: number, high: number) => {
        const dataPoint: any = {};

        dataPoint[ConstantsHelper.DataSeriesLow] = low;
        dataPoint[ConstantsHelper.DataSeriesNormal] = normal;
        dataPoint[ConstantsHelper.DataSeriesHigh] = high;

        const barColors = ['#f75e4c', '#2bda7b', '#ffa700'];
        const barKeys = [
            ConstantsHelper.DataSeriesLow,
            ConstantsHelper.DataSeriesNormal,
            ConstantsHelper.DataSeriesHigh,
        ];

        return {
            height: 20,
            margin: { top: 0, right: 0, bottom: 0, left: 0 },
            indexBy: 'index',
            padding: 0.15,
            enableLabel: false,
            innerPadding: 1,
            borderRadius: 8,
            labelTextColor: 'inherit:darker(1.4)',
            labelSkipWidth: 16,
            labelSkipHeight: 16,
            layout: 'horizontal',
            enableGridY: false,
            enableGridX: false,
            animate: true,
            areaOpacity: 0.15,
            axisBottom: null,
            axisLeft: null,
            axisRight: null,
            axisTop: null,
            colors: isRightToLeft ? barColors.reverse() : barColors,
            data: [dataPoint],
            enablePointLabel: false,
            tooltip: () => <></>,
            keys: isRightToLeft ? barKeys.reverse() : barKeys,
            xScale: null,
            yScale: null,
        } as any;
    };

    return (
        <div className={clsx(styleGeneral.main, styleGuide.insightSummary)}>
            <div className={styleGuide.whiteBackground}>
                <div className={styleGuide.headerSummary}>
                    <div className={styleGuide.timeInRangeSummary}>
                        <div className={clsx(styleGeneral.table, styleGuide.row1)}>
                            <div className={clsx(styleGeneral.row)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    <span className={clsx(styleGeneral.cell, styleGuide.top)}>
                                        <div className={styleGuide.titleAlt}>
                                            <div>{t('timeInRange')}</div>
                                        </div>
                                        <div className={styleGuide.subTitle}>
                                            {t('targetRangeFromTo', {
                                                from: StringHelper.FormatNumber(
                                                    ConstantsHelper.ReadingsGoodEgvMin,
                                                    market,
                                                    1
                                                ),
                                                to: StringHelper.FormatNumber(
                                                    ConstantsHelper.ReadingsGoodEgvMax,
                                                    market,
                                                    1
                                                ),
                                                units: ConstantsHelper.ReadingsUnit,
                                            })}
                                        </div>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row2)}>
                            <div className={styleGeneral.row}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left, styleGuide.dayBoxGreen2)}>
                                    {timeInRangeSettings.timeInRangePct}
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>
                                    <span className={styleGuide.middle}>{t('inRange2')}</span>
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row3)}>
                            <div className={styleGeneral.row}>
                                <span
                                    className={clsx(styleGeneral.cell, styleGuide.chart)}
                                    data-testid={'timesInRangeTestId'}
                                >
                                    <ResponsiveBar
                                        {...getChartSettings(
                                            summary.timeInRangeLowPct,
                                            summary.timeInRangePct,
                                            summary.timeInRangeHighPct
                                        )}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className={clsx(styleGeneral.table, styleGuide.row4)}>
                            <div className={styleGeneral.row}>
                                <span className={clsx(styleGeneral.cell, timeInRangeSettings.styleGuideLeft)}>
                                    <span className={clsx(styleGuide.caption)}>{timeInRangeSettings.captionLeft}</span>
                                    <span className={clsx(styleGuide.value, timeInRangeSettings.styleGuideValueLeft)}>
                                        {timeInRangeSettings.leftPctValue}
                                    </span>
                                </span>
                                <span className={clsx(styleGeneral.cell, timeInRangeSettings.syleGuideRight)}>
                                    <span className={clsx(styleGuide.caption)}>{timeInRangeSettings.captionRight}</span>
                                    <span className={clsx(styleGuide.value, timeInRangeSettings.syleGuideValueRight)}>
                                        {timeInRangeSettings.rightPctValue}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InsightSummaryTimesInRange;

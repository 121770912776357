import pluralize from 'pluralize';

import UiHelper from './UiHelper';
import UtilityHelper from './UtilityHelper';
import ConstantsHelper from './ConstantsHelper';

import { ITranslator } from '../types';

export default class StringHelper {
    public static FormatNumber = (num: number, market: string, numDecimals?: number) => {
        return UtilityHelper.IsNumeric(num)
            ? Intl.NumberFormat(ConstantsHelper.marketConfigurationMap[market].decimalSeparatorLanguageCode, {
                  maximumFractionDigits: numDecimals,
              }).format(Number(num))
            : '';
    };

    public static PercentFormatter = (languageCode: string, inNum: number): string => {
        return isNaN(inNum) ? '' : new Intl.NumberFormat(languageCode, { style: 'percent' }).format(inNum / 100);
    };

    public static Pluralize = (input: number, label = '', showCount = true, market: string): string => {
        const partA: string = showCount ? `${StringHelper.FormatNumber(input, market, 0)} ` : '';
        const partB: string = label.length > 0 ? pluralize(label, input, false) : '';

        return `${partA}${partB}`;
    };

    public static NumberEnglishOrdinalSuffix = (input: number) => {
        const mod10 = input % 10;
        const last2 = Number(`00${input}`.slice(-2));
        let suffix = 'th';

        if (mod10 === 1 && last2 !== 11) {
            suffix = 'st';
        } else if (mod10 === 2 && last2 !== 12) {
            suffix = 'nd';
        } else if (mod10 === 3 && last2 !== 13) {
            suffix = 'rd';
        }

        return suffix;
    };

    public static GetDeltaPct = (
        anI18Nextlib: any,
        value: number,
        translate: ITranslator
    ): {
        prefix: string;
        delta: string;
    } => {
        const valueAbs = Math.abs(value ?? 0);
        const dictSection = 'delta.';
        let prefix = '';
        let delta = translate(`${dictSection}noChange`);

        if (valueAbs > 0) {
            const conditionValueAbs = `${valueAbs}`.substring(0, 1) === '8' || valueAbs === 18 ? 'an' : 'a';
            const keyValue = `${dictSection}${conditionValueAbs}`;
            prefix = `${UiHelper.Translate(anI18Nextlib, keyValue)} `;
            const dictSectionValue = `${dictSection}${value > 0 ? 'increase' : 'decrease'}`;
            delta = `${valueAbs}% ${translate(dictSectionValue)}`;
        }

        return {
            prefix,
            delta,
        };
    };

    public static IsEmptyString = (input: any): boolean =>
        UtilityHelper.IsNull(input) || `${input}`.trim().length === 0;

    public static GetCheckBoxState = (currState: boolean): string => (currState ? 'on' : 'off');
}
